<template>
  <!-- 今日课程 -->
  <div class="supervise-course manage-wrapper">
    <div class="title-wrapper flex-align-between">
      <h3>今日课程</h3>
      <p @click="$router.push('/supervise/schedule')"><i class="el-icon-date"></i> 课程表</p>
    </div>
    <div class="supervise-course-list">
      <ul class="flex-between course-ul">
        <li v-for="(item, index) in list" :key="index">
          <img :src="downloadURL + item.cover" :onerror="$store.state.course.defaultCourseCover[index % 5]" alt
            class="course-cover" />
          <div class="course-info">
            <h3 class="course-name wes-2">{{ item.name }}</h3>
            <div class="course-time flex-align-between">
              <p v-if="item.lessonsPattern === 1">
                {{ handleDate(item.lessonsBeginDate) }}
              </p>
              <p v-if="item.lessonsPattern === 0">
                {{ dayjs(item.lessonsBeginDate).format('YYYY-MM-DD')}}
                {{ dayjs(item.lessonsBeginTime*1000).format('HH:mm') }} -
                {{ dayjs(item.lessonsEndTime*1000).format('HH:mm') }}
              </p>
              <p class="course-status" v-if="item.lessonsPattern === 0" :class="[
                        item.status === 'start' ? 'course-status-red' : ''
                      ]">
                {{ courseStatusMap[item.status].status }}
                <i class="el-icon-s-data" v-if="item.status === 'start'"></i>
              </p>
            </div>
            <div class="course-detail">
              <p>所属项目：{{ item.projectName }}</p>
              <p>主讲教师：{{ item.teacherName }}</p>
              <p>直播出勤学员人数：{{item.studentCount || 0}}人</p>
            </div>
            <div class="flex-align-between course-toolbar">
              <div class="">
                <span class="data-btn btn csp"
                  @click="$router.push(`/teachingdata/${item.courseProjectId}?type=${item.classType}`)">教学数据</span>
                <span class="evaluate-btn btn csp" @click="dialogVisible=true">监课评价</span>
              </div>
              <span class="goto-course-btn" :class="[item.status !== 'start' ? 'button-grey' : '']"
                @click="goPage(item)">
                {{ btnStatus(item) }}
              </span>
            </div>
          </div>
        </li>
        <!-- 占位 [flex-between布局下当一行不足4个元素时起作用]-->
        <li class="placeholder-file file"></li>
        <li class="placeholder-file file"></li>
      </ul>
      <Empty :show="list && list.length === 0" :text="'暂无课节'"></Empty>
    </div>
    <el-dialog title="监课评价" :visible.sync="dialogVisible" width="45%" center>
      <el-input type="textarea" :rows="10" placeholder="请输入评价内容" v-model="textarea" resize="none">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <ToLiveMode :isVisible="downloadVisible" :liveLessonId="currentCourse.id" top="15vh"
      :classType="currentCourse.classType" @downClient="handleDownLoad">
    </ToLiveMode>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        formInline: {
          courseType: "",
          courseStatus: ""
        },
        activeName: "今日课程",
        value1: "",
        projectList: ["今日课程"],
        list: [],
        total: 0,
        currentProjectID: "",
        currentPage: 1,
        pagesize: 3,
        courseStatusMap: {
          unstart: {
            status: "未开始",
            linkText: "进入直播",
            linkClass: "button-grey"
          },
          start: {
            status: "直播中",
            linkText: "进入直播",
            linkClass: ""
          },
          // "end":{'status':"已结束",'linkText':'观看回放','linkClass':''},
          end: {
            status: "已结束",
            linkText: "进入直播",
            linkClass: "button-grey"
          }
        },
        asyncStatusMap: {
          unstart: {
            status: "未开放",
            linkText: "进入课程",
            linkClass: "button-grey"
          },
          end: {
            status: "已开放",
            linkText: "进入课程",
            linkClass: ""
          }
        },
        dialogVisible: false,
        textarea: '',
        downloadVisible: false,
        currentCourse: {},
      };
    },
    computed: {},
    methods: {
      ...mapMutations(["getUrl"]),
      //点击项目列表
      handleClick(tab, event) {
        this.currentProjectID = tab.name;
        // console.log(this.currentProjectID);
        this.getCourseList();
      },
      //改变展示条数
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.pagesize = val;
        this.currentPage = 1;
        this.getCourseList();
      },
      //翻页
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.getCourseList();
      },
      handleDownLoad(data) {
        // console.log(data);
        this.downloadVisible = data.visible;
      },
      //获取项目下课程列表
      async getCourseList(id) {
        let resData = await this.$Api.Course.getTodayCourse();
        // console.log(resData);
        if (resData.data) {
          this.list = resData.data;
          this.total = resData.data.length;
          this.projectList = [`今日课程 ${this.total}`];
          this.activeName = `今日课程 ${this.total}`;
        } else {
          this.list = [];
        }
        // console.log(this.list);
      },
      btnStatus(item) {
        let pattern = item.lessonsPattern;
        let _btn = "";
        if (pattern === 0) {
          let _status = item.status;
          _btn = this.courseStatusMap[_status].linkText;
        } else {
          _btn = "进入课程";
        }

        return _btn;
      },
      //处理数据
      format(percentage) {
        let arr = 10;
        percentage = percentage + "/" + arr;
        return `${percentage}`;
      },
      //跳转页面
      async goPage(item) {
        // console.log(item);
        this.currentCourse = item;
        let path = "/course/";
        let pattern = item.lessonsPattern;
        if (item.status === "start" && pattern === 0) {
          this.$router.push({
            path: "/live",
            query: {
              id: item.id
            }
          })
          // let resData = await this.$Api.Project.live(item.id);
          //  console.log('resData',resData);
          // this.getUrl(resData.data);
          // this.$router.push("/live");
        } else if (pattern === 1) {
          this.$router.push({
            // path: "/asynclive",
            path: '/newasyncLive',
            query: {
              id: item.id
            }
          });
        } else {
          return;
        }
      },
      //处理日期规则
      handleDate(time) {
        let _time = String(time);
        _time = _time.split(" ");
        return _time[0];
      }
    },
    mounted() {
      this.getCourseList();
    }
  };
</script>

<style lang="less" scoped>
  .supervise-course {

    .title-wrapper {
      margin-top: 30px;
      padding: 0 17px 0 21px;
      line-height: 33px;
      background-color: #fff;
      border-radius: 8px;

      h3 {
        font-size: 13px;
        color: #508EF9;
      }

      p {
        cursor: pointer;
        color: #508EF9;
        font-size: 12px;

        i {
          font-size: 16px;
          margin-right: 9px;
          vertical-align: text-bottom;
        }
      }
    }

    .supervise-course-list {
      padding: 0 0 50px 0;

      .course-ul {
        flex-wrap: wrap;
        margin-top: 23px;

        li {
          width: 23.5%;
          box-sizing: border-box;
          margin-bottom: 29px;
          border-radius: 8px;
          background: #fff;
          overflow: hidden;
        }

        .placeholder-file {
          height: 0;
          padding: 0;
          margin: 0 !important;
        }

        //中间图片
        .course-cover {
          display: block;
          margin-bottom: 10px;
          width: 100%;
          height: 128px;
        }

        //li下部分
        .course-info {
          padding: 0 14px 10px 10px;

          .course-name {
            margin-bottom: 8px;
            height: 40px;
            font-size: 13px;
            font-weight: bold;
          }

          .course-time {
            padding-bottom: 7px;
            font-size: 12px;
            color: #999;
          }

          .course-status {
            border: 1px solid #999;
            border-radius: 25px;
            padding: 0 7px;
            line-height: 15px;
            font-size: 12px;
            color: #999;

            &.course-status-red {
              color: #ff0f0f;
              border: 0;
              padding: 0;

              i {
                color: #ff0f0f;
              }
            }
          }

          .course-detail {
            font-size: 12px;
            color: #999;
            line-height: 20px;
            padding: 10px 0;
            border-top: 1px solid #f9f9f9;
            border-bottom: 1px solid #f9f9f9;
          }
        }

        .course-toolbar {
          padding-top: 7px;

          .btn {
            font-size: 12px;
            color: #508EF9;
            cursor: pointer;
            margin-right: 10px;
          }

          .goto-course-btn {
            width: 90px;
            text-align: center;
            background-color: #508EF9;
            border-radius: 4px;
            font-size: 12px;
            line-height: 26px;
            color: #fff;
            cursor: pointer;
          }

          .button-grey {
            background-color: #aaa;
          }
        }
      }
    }

    @media screen and (min-width: 1250px) {
      .title-wrapper {
        margin-top: 36px;
        padding: 0 21px 0 26px;
        line-height: 40px;

        h3 {
          font-size: 16px;
        }

        p {
          font-size: 14px;

          i {
            font-size: 17px;
          }
        }
      }

      .supervise-course-list {

        .course-ul {
          margin-top: 35px;

          li {
            margin-bottom: 35px;
          }

          //中间图片
          .course-cover {
            margin-bottom: 12px;
            height: 155px;
          }

          //li下部分
          .course-info {
            padding: 0 17px 12px 12px;

            .course-name {
              margin-bottom: 12px;
              height: 46px;
              font-size: 16px;
            }

            .course-time {
              padding-bottom: 9px;
              font-size: 14px;
            }

            .course-status {
              padding: 0 9px;
              line-height: 18px;
            }
          }

          .course-toolbar {
            padding-top: 9px;

            .btn {
              font-size: 14px;
              margin-right: 12px;
            }

            .goto-course-btn {
              width: 110px;
              font-size: 14px;
              line-height: 32px;
            }

          }
        }
      }

    }
  }
</style>